<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="(data, row) in path">
        <el-breadcrumb-item :key="row+'a'"><el-link @click="get_bom('mode1', row)">{{ data.sn }}({{ data.model }})</el-link></el-breadcrumb-item>
      </template>
    </el-breadcrumb>
    <br>
    <br>
    <el-row>
      <el-col :span="15"><el-input v-model="bom.screen" placeholder="查找" /></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_bom('mode1', path.length -1)">查找</el-button></el-col>
    </el-row>
    <br>
    <el-row><el-col :span="4"><el-button size="medium" type="success" plain icon="el-icon-circle-plus-outline" @click="click_fun_addMain">添加物料</el-button></el-col></el-row>
    <table v-if="bom.list.data.length > 0" id="bom-table" class="el-table" style="width:auto">
      <tr>
        <th style="text-align:center; ">序号</th>
        <th style="text-align:center; ">编号</th>
        <th style="text-align:center; ">类型</th>
        <th style="text-align:center; ">型号</th>
        <th style="text-align:center; ">用量</th>
        <th style="text-align:center; ">单位</th>
        <th style="text-align:center; ">位号</th>
        <th style="text-align:center; ">描述</th>
        <th style="text-align:center; width:150px"/>
      </tr>
      <template v-for="(data, row) in bom.list.data">
        <tr :key="row">
          <td v-if="row == 0 || bom.list.data[row].sn1 != bom.list.data[row-1].sn1" :rowspan="data.ReplaceSum" style="padding:0px 10px 0px 10px">{{ data.Number }}</td>
          <template v-if="data.SubitemSum == '0'">
            <!-- <td v-if="bom.list.data[row].sn2 != ''" style="color:rgb(201, 178, 178); padding: 0px 10px 0px 10px">{{ data.sn2 }}</td>
            <td v-else style="padding:0px 10px 0px 10px"><el-link @click="get_bom('mode2', row)" >{{ data.sn1 }}</el-link></td> -->
            <td style="padding:0px 10px 0px 10px"><el-link @click="get_bom('mode2', row)" >{{ data.sn1 }}</el-link></td>
          </template>
          <template v-else>
            <td v-if="bom.list.data[row].sn2 != ''" style="padding:0px 10px 0px 10px">
              <el-tooltip class="item" effect="light" content="有子项" placement="left">
                <el-link @click="get_bom('mode2', row)" style="padding: 0px 0px 0px 0px; color:rgb(201, 178, 178)">{{ data.sn2 }}</el-link>
              </el-tooltip>
            </td>
            <td v-else style="padding:0px 10px 0px 10px">
              <el-tooltip class="item" effect="light" content="有子项" placement="left">
                <el-link @click="get_bom('mode2', row)" style="padding: 0px 0px 0px 0px; color:rgb(255, 0, 0)">{{ data.sn1 }}</el-link>
              </el-tooltip>
            </td>
          </template>
          <td style="padding:0px 10px 0px 10px">{{ data.type }}</td>
          <td v-if="bom.list.data[row].sn2 != ''" style="color:rgb(201, 178, 178);padding: 0px 10px 0px 10px">(替代料) {{ data.model }}</td>
          <td v-else style="padding: 0px 10px 0px 10px">{{ data.model }}</td>
          <td style="padding: 0px 10px 0px 10px">{{ data.amounts }}</td>
          <td style="padding: 0px 10px 0px 10px">{{ data.unit }}</td>
          <td style="padding: 0px 10px 0px 10px">{{ data.position }}</td>
          <td style="padding: 0px 10px 0px 10px">{{ data.describe }}</td>
          <td style="padding: 0px 0px 0px 0px">
            <el-tooltip open-delay="1000" class="item" effect="light" content="编辑" placement="top">
              <el-badge class="item">
                <el-button icon="el-icon-edit" size="mini" @click="click_fun_to_edit(row)" />
              </el-badge>
            </el-tooltip>
            <el-tooltip open-delay="1000" class="item" effect="light" content="添加替代料" placement="top">
              <el-badge class="item">
                <el-button v-if="bom.list.data[row].sn2 == ''" icon="el-icon-plus" size="mini" @click="click_fun_to_AddAuxiliary(row)" />
                <el-button v-else icon="el-icon-plus" size="mini" disabled="true"  />
              </el-badge>
            </el-tooltip>
            <el-tooltip open-delay="1000" class="item" effect="light" content="文档" placement="top">
              <el-badge v-if="data.file_sum > 0" :value="data.file_sum" class="item">
                <el-button size="mini" icon="el-icon-document" @click="to_url(data)" />
              </el-badge>
              <el-badge v-else class="item">
                <el-button size="mini" icon="el-icon-document" @click="to_url(data)" />
              </el-badge>
            </el-tooltip>
          </td>
        </tr>
      </template>
    </table>

    <el-button  style="width: 100%;" @click="exportExcel()">导出BOM</el-button>

    <div :v-if="bom.list.data">
      <el-pagination
        hide-on-single-page
        :page-size="bom.list.per_page"
        :page-count="bom.list.last_page"
        :current-page="bom.list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChangeBom"
        @current-change="handleCurrentChangeBom"
      />
    </div>

    <br>
    <template v-if="log_show">
      <table v-if="log_sn0.data.length > 0" class="el-table" style="width:100%">
        <tr>
          <th style="text-align:center; width:'auto'">编号</th>
          <th style="text-align:center; width:'auto'">用户</th>
          <th style="text-align:center; width:'auto'">时间</th>
          <th style="text-align:center; width:'auto'">内容</th>
        </tr>
        <template v-for="(data, row) in log_sn0.data" :v-bind:key="row">
          <tr :key="row">
            <td>{{ data.sn1 }}</td>
            <td>{{ data.uid }}/{{ data.name }}</td>
            <td>{{ data.time }}</td>
            <td>{{ data.log }}</td>
          </tr>
        </template>
      </table>

      <div :v-if="log_sn0.data">
        <el-pagination
          hide-on-single-page
          :page-size="log_sn0.per_page"
          :page-count="log_sn0.last_page"
          :current-page="log_sn0.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChangeLog0"
          @current-change="handleCurrentChangeLog0"
        />
      </div>
    </template>
    <el-button size="mini" icon="el-icon-d-caret" style="width: 100%;" @click="log_show=~log_show">变更记录</el-button>
    <br><br><br>

    <el-dialog title="添加" :visible.sync="addVisible" width="90%" @close="get_bom('mode1', path.length -1)">
      <div>
        <el-row>
          <el-col :span="15"><el-input v-model="material.screen" placeholder="查找" /></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_material">查找</el-button></el-col>
        </el-row>

        <table v-if="material.list.data.length > 0" class="el-table" style="width:100%">
          <tr>
            <th style="text-align:center; width:120px">编号</th>
            <th style="text-align:center; width:350px">型号</th>
            <th style="text-align:center; width:120px">用量</th>
            <th style="text-align:center; width:auto">位号</th>
            <th style="text-align:center; width:200px">描述</th>
            <th style="text-align:center; width:70px">备注</th>
          </tr>
          <template v-for="(data, row) in material.list.data" :v-bind:key="row">
            <tr :key="row">
              <td>{{ data.sn }}</td>
              <td>{{ data.model }}</td>
              <td><el-input v-model="data.amounts" placeholder="用量" /></td>
              <td><el-input v-model="data.position" placeholder="位号" /></td>
              <td><el-input v-model="data.describe" placeholder="描述" /></td>
              <td>
                <el-button icon="el-icon-plus" @click="click_fun_add(row)" />
              </td>
            </tr>
          </template>
        </table>

        <div :v-if="material.list.data">
          <el-pagination
            hide-on-single-page
            :page-size="material.list.per_page"
            :page-count="material.list.last_page"
            :current-page="material.list.current_page"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            @size-change="handleSizeChangeMaterial"
            @current-change="handleCurrentChangeMaterial"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog v-if="edit_value" title="编辑" :visible.sync="editVisible" width="70%" @close="get_bom('mode1', path.length -1)">
      <div>
        <el-row>
          <div style="width: 120px; margin:4px 5px 0px 5px; float:left;">{{ edit_value.sn1 }}</div>
          <div style="width: auto;  margin:4px 5px 0px 5px; float:left;">{{ edit_value.model }}</div>
          <div style="width: 120px; margin:0px 5px 0px 20px; float:left;"><el-button size="mini" icon="el-icon-delete" @click="click_fun_edit('del', '1', '确认删除该物料?')" /></div>
        </el-row>
        <br>
        <el-row>
          <el-col :span="10"><el-input v-model="edit_value.amounts" placeholder="用量"><template slot="prepend">用量</template></el-input></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="click_fun_edit('amounts', edit_value.amounts, '确认修改用量?')">修改</el-button></el-col>
        </el-row>
        <el-row>
          <el-col :span="10"><el-input v-model="edit_value.position" placeholder="位号"><template slot="prepend">位号</template></el-input></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="click_fun_edit('position', edit_value.position, '确认修改位号?')">修改</el-button></el-col>
        </el-row>
        <el-row>
          <el-col :span="10"><el-input v-model="edit_value.describe" placeholder="描述"><template slot="prepend">描述</template></el-input></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="click_fun_edit('describe', edit_value.describe, '确认修改描述?')">修改</el-button></el-col>
        </el-row>
      </div>

      <el-row>
        <el-col :span="20"><el-input v-model="edit_log" type="textarea" placeholder="原因" style="margin-top:10px"><template slot="prepend">原因</template></el-input></el-col>
      </el-row>

      <table v-if="log_sn0.data.length > 0" class="el-table" style="width:100%">
        <tr>
          <!-- <th style="text-align:center; width:'auto'">编号</th> -->
          <th style="text-align:center; width:'auto'">用户</th>
          <th style="text-align:center; width:'auto'">时间</th>
          <th style="text-align:center; width:'auto'">内容</th>
        </tr>
        <template v-for="(data, row) in log_sn1.data" :v-bind:key="row">
          <tr :key="row">
            <!-- <td >{{data.sn1}}</td> -->
            <td>{{ data.uid }}/{{ data.name }}</td>
            <td>{{ data.time }}</td>
            <td>{{ data.log }}</td>
          </tr>
        </template>
      </table>
      <div :v-if="log_sn1.data">
        <el-pagination
          hide-on-single-page
          :page-size="log_sn1.per_page"
          :page-count="log_sn1.last_page"
          :current-page="log_sn1.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChangeLog1"
          @current-change="handleCurrentChangeLog1"
        />
      </div>

    </el-dialog>

  </div>

</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data () {
    return {
      bom: {
        list: {
          data: [],
          total: 1, // 总计
          per_page: 20, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        screen: ''
      },
      log_sn0: {
        data: [],
        screen: '',
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      log_sn1: {
        data: [],
        screen: '',
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      material: {
        list: {
          data: [],
          total: 1, // 总计
          per_page: 10, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        screen: ''
      },
      edit_log: '',
      edit_value: [],
      log_show: false,
      addVisible: false,
      editVisible: false,
      add_material_type: '',
      path: [{ sn: '', model: '根目录' }]
    }
  },
  created: function () {
    let temp = JSON.parse(localStorage.getItem('bomList_path')); if (temp != null) { this.path = temp }
    temp = localStorage.getItem('bomList_current_page'); if (temp > 0) { this.bom.list.current_page = temp };
    this.get_bom('mode1', this.path.length - 1)
  },
  methods: {
    bom_data_init (data) {
      let cnt = 1
      const dt = data
      for (let a = 0; a < dt.data.length; a++) {
        let ReplaceSum = 0
        if (a > 0 && dt.data[a].sn1 !== dt.data[a - 1].sn1) { cnt++ }
        for (let b = 0; b < dt.data.length; b++) {
          if (dt.data[a].sn1 === dt.data[b].sn1) { ReplaceSum++ }
        }
        dt.data[a].Number = cnt
        dt.data[a].ReplaceSum = ReplaceSum
      }
      this.bom.list = dt
      if (dt.data.length === 0) { this.bom.list.current_page = 1 }
      localStorage.setItem('bomList_current_page', this.bom.list.current_page)
    },
    get_bom (mode, row) {
      let sn0 = ''
      if (mode === 'mode1') {
        sn0 = this.path[row].sn
        if (row + 1 < this.path.length) { this.bom.list.current_page = 1 }; this.path.splice(++row, this.path.length - row)
      }
      if (mode === 'mode2') {
        this.bom.list.current_page = 1
        sn0 = this.bom.list.data[row].sn; this.path.push({ sn: sn0, model: this.bom.list.data[row].model })
      }
      this.$axios({
        method: 'post',
        url: '/material/bom/list',
        data: {
          sn0: sn0,
          screen: this.bom.screen,
          per_page: this.bom.list.per_page,
          current_page: this.bom.list.current_page
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bom_data_init(res.data.data)
        }
      })
      this.get_log(this.path[this.path.length - 1].sn, '')
      localStorage.setItem('bomList_path', JSON.stringify(this.path))
    },
    get_material () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_list',
        data: { per_page: this.material.list.per_page, current_page: this.material.list.current_page, mode: 'normal', screen: this.material.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          const dt = res.data.data
          for (let a = 0; a < dt.data.length; a++) {
            dt.data[a].amounts = ''
            dt.data[a].position = ''
            dt.data[a].describe = ''
          }
          this.material.list = dt
        }
      })
    },
    to_url (v) {
      this.$router.push({ path: '/material/file', query: { sn: v.sn } })
    },
    click_fun_edit (type, value, msg) {
      if (this.edit_log === '') { return this.$message.error('请填写修改原因') }
      this.$confirm(msg, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        this.$axios({
          method: 'post',
          url: '/material/bom/edit',
          data: {
            type: type,
            value: value,
            log: this.edit_log,
            sn0: this.edit_value.sn0,
            sn1: this.edit_value.sn1,
            sn2: this.edit_value.sn2
          }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
            this.get_log(this.edit_value.sn0, this.edit_value.sn1)
          }
        })
      })
    },
    get_log (sn0, sn1) {
      this.$axios({
        method: 'post',
        url: '/material/bom/log',
        data: {
          sn0: sn0,
          sn1: sn1,
          per_page: this.log_sn0.per_page,
          current_page: this.log_sn0.current_page
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          if (sn1 === '') { this.log_sn0 = res.data.data } else { this.log_sn1 = res.data.data }
        }
      })
    },
    click_fun_to_edit (row) {
      this.editVisible = true
      this.get_log(this.bom.list.data[row].sn0, this.bom.list.data[row].sn1)
      this.edit_value = JSON.parse(JSON.stringify(this.bom.list.data[row]))
    },
    click_fun_addMain () { // 加主料
      this.get_material()
      this.addVisible = true
      this.add_material_type = this.path[this.path.length - 1].sn
    },
    click_fun_to_AddAuxiliary (row) { // 加辅料
      this.get_material()
      this.addVisible = true
      this.add_material_type = this.bom.list.data[row].sn1
    },
    click_fun_add (row) {
      let sn1, sn2
      const sn0 = this.path[this.path.length - 1].sn
      if (this.add_material_type === this.path[this.path.length - 1].sn) {
        sn1 = this.material.list.data[row].sn
        sn2 = ''
      } else {
        sn1 = this.add_material_type
        sn2 = this.material.list.data[row].sn
      }
      this.$axios({
        method: 'post',
        url: '/material/bom/add',
        data: {
          sn0: sn0,
          sn1: sn1,
          sn2: sn2,
          amounts: this.material.list.data[row].amounts,
          position: this.material.list.data[row].position,
          describe: this.material.list.data[row].describe
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.success(res.data.msg)
          this.get_bom('mode1', this.path.length - 1)
        }
      })
    },
    exportExcel () {
      this.$axios({
        method: 'post',
        url: '/material/bom/list',
        data: {
          screen: '',
          per_page: 10000,
          current_page: 1,
          sn0: this.path[this.path.length - 1].sn
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bom_data_init(res.data.data)
          setTimeout(() => { // 因为数据渲染成表格需要时间, 所以延时一会再导出
            /* out-table关联导出的dom节点  */
            var xlsxParam = { raw: true }// 转换成excel时，使用原始的格式, 无该参数导出时数字可能会异常
            var wb = XLSX.utils.table_to_book(document.querySelector('#bom-table'), xlsxParam)
            /* get binary string as output */
            var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
            try {
              FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.path[this.path.length - 1].model + '(' + this.path[this.path.length - 1].sn + ')' + '.xlsx')
            } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
            return wbout
          }, 100)
        }
      })
    },
    handleSizeChangeLog0 (val) {
      this.log_sn0.per_page = val
      this.get_log(this.path[this.path.length - 1].sn, '')
    },
    handleCurrentChangeLog0 (val) {
      this.log_sn0.current_page = val
      this.get_log(this.path[this.path.length - 1].sn, '')
    },
    handleSizeChangeLog1 (val) {
      this.log_sn0.per_page = val
      this.get_log(this.path[this.path.length - 1].sn, '')
    },
    handleCurrentChangeLog1 (val) {
      this.log_sn0.current_page = val
      this.get_log(this.path[this.path.length - 1].sn, '')
    },
    handleSizeChangeBom (val) {
      this.bom.list.per_page = val
      this.get_bom('mode1', this.path.length - 1)
    },
    handleCurrentChangeBom (val) {
      this.bom.list.current_page = val
      this.get_bom('mode1', this.path.length - 1)
    },
    handleSizeChangeMaterial (val) {
      this.material.list.per_page = val
      this.get_material()
    },
    handleCurrentChangeMaterial (val) {
      this.material.list.current_page = val
      this.get_material()
    }
  }
}
</script>

<style lang="less" scoped>
  td{
      padding: 0px 10px 0px 10px
  }
</style>
