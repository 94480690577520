import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import Print from 'vue-print-nb'
import axios from 'axios' // 引入axios

import VxeUI from 'vxe-pc-ui'
import 'vxe-table/lib/style.css'
import 'vxe-pc-ui/lib/style.css'
import VxeUITable from 'vxe-table'

Vue.use(Print)
Vue.use(VxeUI)
Vue.use(VxeUITable)

Vue.prototype.$axios = axios // 把axios挂载到vue上
// axios.defaults.baseURL = 'http://erp_api.wei-hz.com/'
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL
axios.interceptors.request.use(config => {
  // 为请求头对象，添加token验证的Authorization字段
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})

axios.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效
  if (response.data.status === 10010) {
    // alert(JSON.stringify(response))
    window.sessionStorage.setItem('token', '') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）

    const userConfirmed = confirm('您的会话已过期，请重新登录。是否跳转到登录页?')
    if (userConfirmed) {
      router.replace({
        path: '/login' // 到登录页重新获取token
      })
    }
  }
  return response
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
