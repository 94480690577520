<template>
    <div>
      <table v-if="task && list.data.length" style="width: 100%;">
        <tr>
          <td style="width: auto; text-align: left;">{{ task.title }}</td>
          <td style="width: 280px; text-align: right;">{{ list.data[0].user }}({{ list.data[0].uid }})/{{ list.data[0].time }}</td>
        </tr>
      </table>

      <div id="jsmind_container" ref="jsmindContainer"></div>
      <div id="jsmindPagination">
        <el-pagination
          hide-on-single-page
          :page-size="list.per_page"
          :page-count="list.last_page"
          :current-page="list.current_page"
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
      <div id="context-menu" ref="contextMenu">
        <ul>
          <li @click="NodeEdit('NodeAdd')">添加节点</li>
          <li @click="NodeEdit('NodeExpand')">展开全部</li>
          <li @click="NodeEdit('NodeDelete')">删除节点</li>
          <li @click="NodeEdit('UpLoadData')">上传云端</li>
        </ul>
      </div>

      <vxe-modal title="编辑" :before-hide-method="QuillEditorEsc" v-model="quillEdit.Dialog_Visible" resize esc-closable width="50%" height="50%" v-if="jsmind.currentNode">
        <label @click="quillEdit.AddrBookEdit_Visible=true">责任人: </label>
        <span v-for="(v,k) in jsmind.currentNode.data.users" :key="k">{{ v.name }} </span>
        <br>
        <vxe-text>计划完成时间节点</vxe-text>
        <vxe-date-picker
          type="datetime"
          placeholder="年-月-日 时:分"
          value-format="yyyy-MM-dd HH:mm"
          label-format="yyyy-MM-dd HH:mm"
          v-model="jsmind.currentNode.data.endTime">
        </vxe-date-picker>
        <vxe-select v-model="jsmind.currentNode.data.state" prefix-icon="vxe-icon-flag">
          <vxe-option value="执行" label="执行"></vxe-option>
          <vxe-option value="完成" label="完成"></vxe-option>
          <vxe-option value="暂停" label="暂停"></vxe-option>
          <vxe-option value="关闭" label="关闭"></vxe-option>
        </vxe-select>
        <vxe-modal title="用户选择" v-model="quillEdit.AddrBookEdit_Visible" :width="300" :height="500">
          <table style="width: 100%;">
            <tr>
              <th style="padding: 0px 20px 0px 20px; text-align: center;">通讯录</th>
              <th style="padding: 0px 20px 0px 20px; text-align: center;">已选择</th>
            </tr>
            <tr>
              <td style="text-align: center;">
                <template v-for="(v1, k1) in addrBook">
                  <el-tag :key="k1" @click="EditUser('add', v1)">{{ v1.name }}</el-tag><br :key="k1+'a'">
                </template>
              </td>
              <td style="text-align: center;">
                <template v-for="(v2, k2) in jsmind.currentNode.data.users">
                  <el-tag :key="k2" closable @close="EditUser('del', v2)">{{ v2.name }}</el-tag><br :key="k2+'b'">
                </template>
              </td>
            </tr>
          </table>
        </vxe-modal>

        <quill-editor
          class="editor"
          :ref="'QuillEditor'"
          :options="editorOptions(jsmind.currentNode.id, jsmind.currentNode.topic)"
          v-model="jsmind.currentNode.topic"
        ></quill-editor>
        <span style="font-size: 8px;color: #e0e0e0;position: absolute;bottom: 10px;right: 10px;">{{ jsmind.currentNode.id }}</span>
      </vxe-modal>
    </div>
  </template>

<script>
import jsMind from 'jsmind'
import 'jsmind/style/jsmind.css'
import { VxeUI } from 'vxe-pc-ui'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
require('jsmind/es6/jsmind.draggable-node.js')

export default {
  data () {
    return {
      task: null,
      oldValue: '',
      addrBook: {},
      list: {
        data: [],
        total: 1,
        per_page: 1,
        last_page: 1,
        current_page: 1
      },
      jsmind: {
        jm: null,
        currentNode: null
      },
      quillEdit: {
        Dialog_Visible: false,
        AddrBookEdit_Visible: false
      }
    }
  },
  components: {
    quillEditor
  },
  created: function () {
    try {
      if (this.$route.query.data.title) {
        localStorage.setItem('NodeEdit_task', JSON.stringify(this.$route.query.data))
      }
    } catch (e) {}
    // const scale = Math.round(window.innerWidth / window.outerWidth * 100)
    this.task = JSON.parse(localStorage.getItem('NodeEdit_task')); this.get_node(); this.get_addr_book()
    // if (scale !== 100) { VxeUI.modal.message({ content: '当前浏览器缩放比例会影响思维导图的展示效果, 请按 "Ctrl + 0"恢复默认缩放，以获得最佳体验。', status: 'warning' }) }
  },
  mounted () {
    this.JsmindInit()
    this.observeResize()
    window.addEventListener('resize', this.observeResize)
  },
  methods: {
    get_node () { // 从服务器获取脑图数据
      const self = this
      self.$axios({
        method: 'post',
        url: '/notes/node/get_node',
        data: { number: self.task.number, per_page: self.list.per_page, current_page: self.list.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          self.list = res.data.data
          this.oldValue = self.list.data[0].log
          const obj = JSON.parse(self.list.data[0].log)
          this.base64ToStr(obj.data); self.list.data[0].log = obj
          /* eslint-disable new-cap */
          this.jsmind.jm.show(self.list.data[0].log)
          /* eslint-enable new-cap */
        }
      })
    },
    get_addr_book () { // 从服务器获取通讯录数据
      this.$axios({
        method: 'post',
        url: '/user/index/get_addr_book',
        data: { }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          VxeUI.modal.message({ content: res.data.msg, status: 'warning' })
        } else {
          this.addrBook = {}
          res.data.data.forEach((value) => {
            this.addrBook[value.id] = value
          })
        }
      })
    },
    JsmindInit () { // 脑图参数初始化
      if (this.jsmind.jm === null) {
        const self = this
        const options = {
          mode: 'side',
          editable: true,
          theme: 'default',
          support_html: true,
          container: this.$refs.jsmindContainer,
          view: {
            engine: 'canvas',
            draggable: true, // 启用拖动画布功能
            expander_style: 'number', // 使用数字显示子节点数量
            node_overflow: 'wrap', // 对文本进行换行, 以展示全部文本内容
            hide_scrollbars_when_draggable: false, // 启用拖动时隐藏滚动条
            enable_device_pixel_ratio: true, // 启用按设备像素比例绘制高清思维导图
            zoom: {
              min: 0.01, // 最小缩放比例
              max: 1.0, // 最大缩放比例
              step: 0.2 // 缩放步长
            },
            custom_node_render: function (jm, el, node) { // 重构节点的渲染方法
              let html = ''
              const size = 12
              // 检查并移除class "root",根节点不需要单独的样式
              if (el.classList && el.classList.contains('root')) { el.classList.remove('root') }

              html += `<lable style="font-size:${size}px;">状态: </lable>`
              if (node.data.state === '暂停') {
                html += `<span style="font-size:${size}px;color:#ff0000;">${node.data.state}</span>`
              } else if (node.data.state === '关闭') {
                html += `<span style="font-size:${size}px;color:#ffb6c1;">${node.data.state}</span>`
              } else if (node.data.state === '完成') {
                html += `<span style="font-size:${size}px;color:#00ff00;">${node.data.state}</span>`
              } else {
                html += `<span style="font-size:${size}px;">${node.data.state}</span>`
              }

              html += '<br>'
              if (Object.keys(node.data.users).length > 0) {
                html += `<lable style="font-size:${size}px;">责任人: </lable>`
              } else {
                html += `<lable style="font-size:${size + 5}px;color:#ff0000;">责任人: </lable>`
              }
              for (const key in node.data.users) {
                html += `<span style="font-size:${size}px;">${node.data.users[key].name} </span>`
              }

              html += '<br>'
              const endTime = new Date(`${node.data.endTime}`)
              const nowTime = new Date(`${self.getFormattedDate(0)}`)
              html += `<lable style="font-size:${size}px;">计划完成时间: </lable>`
              if (endTime > nowTime || node.data.state !== '执行') {
                html += `<span style="font-size:${size}px;">${node.data.endTime}</span>`
              } else {
                const offsetDays = ((nowTime - endTime) / (1000 * 60 * 60 * 24)).toFixed(2)
                html += `<span style="font-size:${size + 5}px;color:#ff0000;">${node.data.endTime}, 已超时${offsetDays}天</span>`
              }

              html += '<hr style="border: none; border-top: 1px solid #d3d3d3; margin:  0px 0px 6px 0px;">'
              el.innerHTML = html + node.topic + `<p style="font-size:${size - 5}px;text-align:right;color:#FaFaFa;">${node.id}</p>`
              return true
            }
          }
        }
        /* eslint-disable new-cap */
        this.jsmind.jm = new jsMind(options)
        /* eslint-enable new-cap */

        document.addEventListener('click', this.hideContextMenu) // 添加左单击事件监听
        this.$refs.jsmindContainer.addEventListener('contextmenu', this.showContextMenu)// 添加右键事件监听
        this.jsmind.jm.view.add_event(this.jsmind.jm.view.e_canvas, 'dblclick', (event) => { // 双击编辑
          const selectedNode = this.jsmind.jm.get_selected_node()
          if (!selectedNode) return
          this.jsmind.jm.end_edit()
          this.quillEdit.Dialog_Visible = true
          this.jsmind.currentNode = selectedNode
        })
      }
    },
    showContextMenu (event) { // 右击鼠标事件函数
      event.preventDefault()
      const selectedNode = this.jsmind.jm.get_selected_node()
      if (!selectedNode) return
      this.jsmind.currentNode = selectedNode
      const contextMenu = this.$refs.contextMenu
      contextMenu.style.display = 'block'
      contextMenu.style.top = `${event.clientY}px`
      contextMenu.style.left = `${event.clientX}px`
    },
    hideContextMenu () { // 左单击隐藏菜单
      this.$refs.contextMenu.style.display = 'none'
    },
    editorOptions (keyVal, log) { // 富文本编辑器参数
      return {
        modules: {
          toolbar: [
            ['clean'], // 清除文本格式
            [{ align: [] }], // 对齐方式
            [{ color: [] }, { background: [] }], // 颜色
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            [{ size: ['small', false, 'large', 'huge'] }] // 字体大小
          ]
        }
      }
    },
    NodeEdit (type) { // 节点编辑
      const self = this
      if (type === 'NodeAdd') {
        if (this.jsmind.currentNode) {
          const users = {}
          const state = '执行'
          const nodeId = `node_${Date.now()}`
          const endTime = this.getFormattedDate(24)
          this.jsmind.jm.add_node(this.jsmind.currentNode, nodeId, '新节点', { endTime: endTime, state: state, users: users })
        }
      } else if (type === 'NodeDelete') {
        if (this.jsmind.currentNode) {
          this.$confirm('是否确认删除节点', '提示', {
            confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
          }).then(() => { this.jsmind.jm.remove_node(this.jsmind.currentNode.id) })
        }
      } else if (type === 'NodeExpand') { // 展开节点
        this.jsmind.jm.expand_all()
      } else if (type === 'UpLoadData') {
        const obj = this.jsmind.jm.get_data()
        this.StrTobase64(obj.data)
        const id = self.list.data[0].id
        const value = JSON.stringify(obj)
        const number = self.list.data[0].number

        this.$axios({
          method: 'post',
          url: '/notes/node/updata',
          data: { type: 'EditLog', number: number, id: id, value: value }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.get_node()
            this.$message.success(res.data.msg)
          }
        })
      }
    },
    QuillEditorEsc () { // 退出富文本编辑器
      this.quillEdit.AddrBookEdit_Visible = false
      this.jsmind.jm.update_node(this.jsmind.currentNode.id, this.jsmind.currentNode.topic)
    },
    EditUser (type, dat) { // 修改节点责任人
      if (type === 'add') { this.$set(this.jsmind.currentNode.data.users, dat.id, dat) } else if (type === 'del') { this.$delete(this.jsmind.currentNode.data.users, dat.id) }
    },
    getFormattedDate (hoursToAdd) { // 获取日期
      const date = new Date()// 加上指定的小时数
      date.setTime(date.getTime() + hoursToAdd * 60 * 60 * 1000) // 转为毫秒后加上
      const year = date.getFullYear()// 格式化年月日时分秒
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0') // 确保为24小时制
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}`// 返回格式化的日期时间字符串
    },
    base64ToStr (node) { // base64转字符串
      if (node.topic) { // 将 topic 转换为新的值并存回原位置
        node.topic = new TextDecoder().decode(Uint8Array.from(atob(node.topic), c => c.charCodeAt(0))) // base64转字符串
      }
      if (node.children && Array.isArray(node.children)) { // 如果有子节点，递归处理
        node.children.forEach(child => this.base64ToStr(child))
      }
    },
    StrTobase64 (node) { // 字符串转base64
      if (node.topic) { // 将 topic 转换为新的值并存回原位置
        const utf8Bytes = new TextEncoder().encode(node.topic)
        const base64 = btoa(String.fromCharCode(...utf8Bytes))
        node.topic = base64 // 字符串转base64
      }
      if (node.children && Array.isArray(node.children)) { // 如果有子节点，递归处理
        node.children.forEach(child => this.StrTobase64(child))
      }
    },
    handleCurrentChange (val) { // 翻页
      this.list.current_page = val; this.get_node()
    },
    observeResize () { // 自适应脑图容器大小
      const container = this.$refs.jsmindContainer
      const divApp = document.getElementById('app')
      const divHeader = document.getElementById('divHeader')
      const divSidebar = document.getElementById('cDivSidebar')
      const pagination = document.getElementById('jsmindPagination')

      // 监听 cDivSidebar 尺寸变化
      const resizeObserver = new ResizeObserver(entries => {
        container.style.width = `${divApp.offsetWidth - divSidebar.offsetWidth - 5}px`
        container.style.height = `${divApp.offsetHeight - divHeader.offsetHeight - 80}px`
        // 将 pagination 定位到 container 下面
        pagination.style.position = 'absolute'; pagination.style.top = `${container.offsetHeight}px`
      })
      resizeObserver.observe(divSidebar)
    }
  },
  beforeDestroy () { // 清除事件监听器
    window.removeEventListener('resize', this.observeResize)
    document.removeEventListener('click', this.hideContextMenu)
    this.$refs.jsmindContainer.removeEventListener('contextmenu', this.showContextMenu)
  },
  beforeRouteLeave (to, from, next) { // 监听拦截页面跳转
    const nowValue = this.jsmind.jm.get_data(); this.StrTobase64(nowValue.data)
    if (JSON.stringify(nowValue) !== this.oldValue) {
      if (window.confirm('数据有修改,切换页面数据将丢失, 确认离开吗?')) {
        next() // 允许跳转
      } else { next(false) }// 阻止跳转
    } else { next() }// 允许跳转
  }
}
</script>

<style lang="less" scoped>
  #jsmind_container {
    flex: 1;
    margin: 0;
    padding: 0;
    position: absolute;
    border: 1px solid #ccc;
    background-color:#f0f0f0;
  }
  #context-menu {
    position: absolute;
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  #context-menu ul {
    list-style: none;
    margin: 0;
    padding: 5px;
  }
  #context-menu ul li {
    padding: 8px 12px;
    cursor: pointer;
  }
  #context-menu ul li:hover {
    background: #f0f0f0;
  }
  #jsmindPagination {
    margin-top: 90px;
  }
  ::v-deep p {
    margin: 0 !important;
    padding: 0 !important;
  }
</style>
