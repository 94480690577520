<template>
  <div>
    <vxe-modal :title="taskEdit.title" v-model="taskEdit.Dialog_Visible" show-maximize resize esc-closable>
      <el-input v-if="taskEdit.type=='EditTitle'" placeholder="请输入内容" v-model="list.data[taskEdit.index].title" >
        <el-button slot="append"  @click="TaskEdit('EditTitle', taskEdit.index)" icon="vxe-icon-save"></el-button>
      </el-input>
      <el-input v-if="taskEdit.type=='AddTask'" placeholder="请输入内容" v-model="taskEdit.value">
        <el-button slot="append" @click="TaskEdit('AddTask', taskEdit.value)" icon="vxe-icon-add"></el-button>
      </el-input>
    </vxe-modal>

    <table class="el-table" style="width:auto">
      <template v-for="(v1, k1) in list.data">
        <tr :key="k1+'b'">
          <td style="padding-right: 10px;">{{ k1+1 }}</td>
          <td style="padding-right: 10px;">{{ v1.uid }}/{{ v1.user }}</td>
          <td style="padding-right: 10px;" @click="TaskEdit('ClickTitle', k1)">{{ v1.title }}</td>
          <td style="padding-right: 10px;">{{ v1.time }}</td>
          <td><vxe-button size="mini" icon="vxe-icon-flow-branch" @click="$router.push({ path: '/node/edit', query: { data: v1 } })"></vxe-button></td>
          <td><vxe-button size="mini" icon="vxe-icon-user" @click="TaskEdit('ClickUserEdit', v1)"></vxe-button></td>
          <td><vxe-button size="mini" icon="vxe-icon-delete" @click="TaskEdit('TaskDel', v1)"></vxe-button></td>
        </tr>
      </template>
        <tr>
          <td colspan="7" style="text-align:center;"><vxe-button style="width: 100%; text-align: center;" icon="vxe-icon-add" @click="TaskEdit('ClickAddTask', taskEdit.index)"></vxe-button></td>
        </tr>
    </table>

    <vxe-modal v-if="Auth.data" title="用户授权" v-model="Auth.Dialog_Visible" :width="300" :height="500">
      <div style="width: 99%; max-height: 395px; overflow-y: auto; border: 1px solid #ddd;">
        <table style="width: 100%;">
          <tr>
            <th style="padding: 0px 20px 0px 20px; text-align: center;">通讯录</th>
            <th style="padding: 0px 20px 0px 20px; text-align: center;">已选择</th>
          </tr>
          <tr>
            <td style="text-align: center;">
              <template v-for="(v1, k1) in Auth.users">
                <el-tag :key="k1" @click="TaskEdit('UserAdd', v1)">{{ v1.name }}</el-tag><br :key="k1+'a'">
              </template>
            </td>
            <td style="text-align: center;">
              <template v-for="(v2, k2) in Auth.data.users">
                <el-tag :key="k2" closable @close="TaskEdit('UserDel', k2)">{{ v2 }}</el-tag><br :key="k2+'b'">
              </template>
            </td>
          </tr>
        </table>
      </div>
      <vxe-button style="width: 100%; text-align: center;" @click="TaskEdit('UserUpload', Auth.data)">保存修改</vxe-button>
    </vxe-modal>

  </div>
</template>

<script>

export default {
  data () {
    return {
      list: {
        data: [],
        total: 1,
        per_page: 200,
        last_page: 1,
        current_page: 1
      },
      taskEdit: {
        type: '',
        index: 0,
        title: '',
        Dialog_Visible: false
      },
      uid: null,
      Auth: { data: null, users: [], Dialog_Visible: false }
    }
  },
  created: function () {
    this.get_list()
    this.get_users()
    this.uid = window.sessionStorage.getItem('user_uid')
  },
  methods: {
    TaskEdit (type, val) {
      if (type === 'ClickTitle') {
        this.taskEdit.index = val
        this.taskEdit.type = 'EditTitle'
        this.taskEdit.title = '编辑主题'
        this.taskEdit.Dialog_Visible = true
      } else if (type === 'EditTitle') {
        this.$axios({
          method: 'post',
          url: '/notes/node/updata',
          data: { type: 'EditTitle', number: this.list.data[val].number, value: this.list.data[val].title }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
        })
      } else if (type === 'ClickAddTask') {
        this.taskEdit.type = 'AddTask'
        this.taskEdit.title = '添加主题'
        this.taskEdit.Dialog_Visible = true
      } else if (type === 'AddTask') {
        const obj = {
          meta: {
            name: 'example',
            author: 'jsmind',
            version: '1.0'
          },
          format: 'node_tree',
          data: {
            id: `root_${Date.now()}`,
            topic: '5qC56IqC54K5',
            children: [],
            users: {},
            state: '执行',
            endTime: this.getFormattedDate(72)
          }
        }
        this.$axios({
          method: 'post',
          url: '/notes/node/updata',
          data: { type: 'AddTitle', log: JSON.stringify(obj), value: val }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
          this.get_list()
        })
        this.taskEdit.Dialog_Visible = false
      } else if (type === 'TaskDel') {
        if (this.uid === val.uid || val.uid === 1) {
          this.$confirm('是否确认删除', '提示', {
            confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
          }).then(() => {
            this.$axios({
              method: 'post',
              url: '/notes/node/updata',
              data: { type: 'Del', number: val.number, value: val.id }
            }).then(res => {
              if (parseInt(res.data.status) !== 0) {
                this.$message.error(res.data.msg)
              } else {
                this.get_list()
                this.$message.success(res.data.msg)
              }
            })
          })
        } else { return this.$message.error('非创建者, 所以无删除权限') }
      } else if (type === 'ClickUserEdit') {
        this.Auth.data = val
        this.Auth.Dialog_Visible = true
      } else if (type === 'UserAdd') {
        this.$set(this.Auth.data.users, val.uid, val.name)
      } else if (type === 'UserDel') {
        let flag = false
        if (val === this.uid) {
          return this.$message.error('禁止删除自已')
        } else {
          this.Auth.users.forEach((value) => {
            if (value.uid === parseInt(val)) { flag = true }
          })
        }
        if (flag) { this.$delete(this.Auth.data.users, val) } else { return this.$message.error('无权删除该用户') }
      } else if (type === 'UserUpload') {
        this.$axios({
          method: 'post',
          url: '/notes/node/updata',
          data: { type: 'EditUsers', number: val.number, value: val.users }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
        })
      }
    },
    get_list () {
      const self = this
      this.$axios({
        method: 'post',
        url: '/notes/node/get_list',
        data: { per_page: this.list.per_page, current_page: this.list.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          res.data.data.data.forEach((value) => {
            value.users = JSON.parse(value.users)
          })
          self.list = res.data.data
        }
      })
    },
    get_users () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_list',
        data: { GetAll: 'yes', per_page: 10000, current_page: 1 }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.Auth.users = res.data.data.list.data
        } else { this.$message.error(res.data.msg) }
      })
    },
    getFormattedDate (hoursToAdd) {
      const date = new Date()// 加上指定的小时数
      date.setTime(date.getTime() + hoursToAdd * 60 * 60 * 1000) // 转为毫秒后加上
      const year = date.getFullYear()// 格式化年月日时分秒
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0') // 确保为24小时制
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}` // 返回格式化的日期时间字符串
    }
  }
}
</script>

<style lang="less" scoped>
</style>
